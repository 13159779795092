"use client";

import { useCallback, useContext } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { I18nContext, LocaleContext } from "@finbackoffice/site-core";
import { IUserInterfaceTranslationResponse } from "@finbackoffice/clientbff-client";

export const useSetI18nextResources = (): {
    setTranslationResources: (
        translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>,
    ) => void;
} => {
    const { locale } = useContext(LocaleContext);
    const { i18n } = useContext(I18nContext);

    const setTranslationResources = useCallback(
        (translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>) => {
            Object.keys(translations).forEach((scope) => {
                i18n.addResourceBundle(
                    locale,
                    scope,
                    translations[scope as TranslationScopes],
                    true,
                    true,
                );
            });
        },
        [i18n, locale],
    );

    return { setTranslationResources };
};
