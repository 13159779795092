"use client";

import { TranslationScopes } from "@finbackoffice/enums";
import {
    ClientBFFContext,
    getTranslations,
    I18nContext,
    IDeviceType,
    LocaleContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { useContext, useEffect, useMemo, useState } from "react";
// import {
//     ClientBFFClient,
//     IGetUserInterfaceRequest,
//     IUserInterfaceMapResponse,
// } from "@finbackoffice/clientbff-client";
// refactor

// @TODO move this
//
// type IGetTranslationsConfig = {
//     client: ClientBFFClient;
//     request: IGetUserInterfaceRequest;
//     // includeLocalTranslations: boolean;
//     deviceType: IDeviceType;
//     localOrigin?: string;
// };
//
// async function getTranslations(config: IGetTranslationsConfig): Promise<IUserInterfaceMapResponse> {
//     const { client, request, deviceType } = config;
//     const translations =
//         deviceType === "mobile"
//             ? await client.getMobileTranslations(request)
//             : await client.getDesktopTranslations(request);
//
//     return translations;
// }

export const useLoadI18nextResource = (
    value: TranslationScopes,
    deviceType: IDeviceType = "mobile",
): { isTranslationsReady: boolean } => {
    const VERSION = useRuntimeConfig("VERSION");
    const client = useContext(ClientBFFContext);
    const { locale } = useContext(LocaleContext);
    const { i18n } = useContext(I18nContext);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsReady(false);
        async function load() {
            const response = await getTranslations({
                client,
                request: {
                    language: locale,
                    scopes: [value],
                },
                deviceType,
                includeLocalTranslations: VERSION === "local",
            });

            i18n.addResourceBundle(locale, value, response[value], true, true);
            setIsReady(true);
        }

        void load();
    }, [i18n, locale, deviceType, client, value, VERSION]);

    return useMemo(() => ({ isTranslationsReady: isReady }), [isReady]);
};
